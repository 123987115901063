.toggle-button {
    /* Add more styles as needed, based on the SVG content */
  }

  .button-content {
    margin-left: 12px;
    margin-bottom: 12px;
  }

  .toggle-button img {
    cursor: pointer;
  }
  
  .clicked .button-content p {
    /* Styles for the button content when it's clicked */
    /* Defines the white space inside the borders of the container. Adjust as needed */
    border-right: .1em solid orange; /* Simulates the cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    width: 0; /* Start with 0 width and animate to the full width */
    /* Adjust the animation speed as necessary */
    animation: drawText 2s steps(40, end) forwards, blink-caret 1s step-end infinite;
    /* Ensure your font properties here */
    font-family: monospace;
    font-size: 11px;
  }
  
  .drawing-border {
    /* Styles for the animated border */
    position: absolute;
    border: 2px solid #FEE0DE; /* Customize as needed */
    animation: drawBorder 1s linear forwards;
  }
  
  @keyframes drawBorder {
    from {
      width: 0;
    }
    to {
      width: 100%; /* Adjust as needed */
    }
  }

@keyframes drawText {
    from {
        width: 0;
    }
    to {
        width: max-content;
    }
}

@keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: orange; }
  }
  