/* Referrals.css */
.referrals {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    margin: 20px;
    width: 80%;
  }

  .referrals-header {
    display: flex;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
    background: #BAEAC5;
    padding-left: 10px;
    width: 100%;
  }

  .referrals-header p {
    font-size: 12px !important;
    font-weight: 600 !important;
    margin-left: 8px !important;
    text-transform: uppercase !important; /* To match your design */
    color: #4a4a4a !important; /* Adjust as needed */
    margin-top: 12px !important;
  }

  .referrals-header img {
    cursor: pointer;
  }
  