/* Montserrat.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');
/* CSS rules for Montserrat font styles */

/* Poppins.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
/* CSS rules for Poppins font styles */

/* Inter.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
/* CSS rules for Poppins font styles */


.home {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #F5F6FA;
  height: 100vh;
  overflow: auto; /* Add this line to enable scrolling when content overflows */
}

/* Styles for the left sidebar */
.left-sidebar {
  width: 30%; /* Adjust the width as needed */
  background-color: #F6F7FC;
  font-weight: bold;
  color: #333; /* Medium black */
  height: 100%;
}

/* Styles for the content area (top bar and center boxes) */
.content {
  width: 30%; /* Adjust the width as needed */
  display: flex;
  flex-direction: column;
}

/* Styles for the right sidebar */
.right-sidebar {
  width: 40%; /* Adjust the width as needed */
  padding: 20px;
}

.left-sidebar {
  position: relative;
}

.PAPA {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
}