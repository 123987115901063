.resource-card {
        width: 300px;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.20);
        overflow: hidden;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

.drawing-border {
    /* Styles for the animated border */
    border: 2px solid #FEE0DE; /* Customize as needed */
    animation: drawBorder 1s linear forwards;
  }
  
  @keyframes drawBorder {
    from {
      width: 0;
    }
    to {
      width: 100%; /* Adjust as needed */
    }
  }
  
  .resource-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .resource-child-expanded {
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 5px;
    background: #EFEFEF;
    margin-top: 10px;
  }

.resource-child.expanded {
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 5px;
    background: #EFEFEF;
}

  .back-icon {
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(0deg, #E2E5FF 0%, #E2E5FF 100%), #B6DDEA;
    color: var(--Colour-Periwinkle, #707EFF);
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 6px; /* 100% */
    margin-right: 3px;
    cursor: pointer;
  }


  .resource-icon {
    padding: 7px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(0deg, #E2E5FF 0%, #E2E5FF 100%), #B6DDEA;
    color: var(--Colour-Periwinkle, #707EFF);
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 6px; /* 100% */
  }
  
  .high-impact-match {
    background: linear-gradient(133deg, #C53EF6 -22.74%, #FF7461 123.29%);
    border-radius: 10px;
    padding: 3px 8px;
    margin-left: 3px;
    color: #FFF;
    font-size: 0.8em;
    font-weight: bold;
  }
  
  .resource-content {
    color: #182A72;
    cursor: pointer;
  }

  .main-title {
    color: #182A72;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    padding-top: 11px;
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 11px;
    line-height: normal;
  }

  .main-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  .res-button {
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid var(--Colour-Periwinkle, #707EFF);
    background: #D6DAFE;
    color: #182A72;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .prog-button {
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #BAEAC5;;
    color: #182A72;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .reload {
    margin-top: 10px;
    width: 72%;
    display: flex;
    padding: 5px 8px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    color: #83659E;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 20px;
    border: 1px solid #83659E;
    background: #FFF;
  }
  
  .title {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
.tags {
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
    flex-wrap: wrap; /* Add this line */
}

.tag {
    background-color: #E2E5FF;
    border-radius: 10px;
    padding: 3px 8px;
    color: #707EFF;
    font-size: 0.8em;
}
  
  .summary {
    font-size: 0.75em;
    color: #666;
    margin-bottom: 10px;
  }
  
  .icon-link {
    cursor: pointer;
  }
  