/* ChatWindow.css */
*, *::before, *::after {
  box-sizing: border-box;
}

.chat-window {
  background: #FFFFFF;
  border-radius: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100vh;
  margin-bottom: 20px;
  position: relative; /* Added for border animation */
}

.chat-header {
  padding: 10px 20px;
  border-bottom: 1px solid #ECECEC;
  flex-shrink: 0;
}

.chat-partner-name {
  background: #f0f0f0;
  border-radius: 20px;
  padding: 5px 15px;
  margin: 0;
  font-size: 12px;
}

.chat-messages {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 150px - 150px);
}

.chat-message {
  background: #F7F7F7;
  border-radius: 20px;
  margin-bottom: 10px;
  padding: 15px;
  max-width: 60%;
  align-self: flex-start;
}

.message-content {
  margin-bottom: 5px;
}

.message-meta {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
}

.message-author {
  font-weight: bold;
}

.message-time {
  color: #999999;
}

.chat-input {
  border-top: 1px solid #ECECEC;
  padding: 10px;
  display: flex;
  flex-shrink: 0;
}

.chat-input textarea {
  flex: 1;
  border: none;
  border-radius: 20px;
  padding: 10px;
  margin-right: 10px;
}

.chat-input button {
  background: #D6DAFE;
  color: var(--gradient-periwinkle);
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 600;
}

.chat-message.right {
  align-self: flex-end;
}