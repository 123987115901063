.sidebar {
    width: 7%;
    background-color: #F6F7FC;
    font-style: italic;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  .peerlogo {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  .sidebar a {
    color: var(--ui-dark-purple, #4E2375);
    font-family: 'Montserrat';
    font-size: 15px;
    font-style: italic;
    text-decoration: solid;
    margin-bottom: 15px;
    line-height: 24px;
  }

  .logout-button {
    border-radius: 46px;
    background: var(--gradient-periwinkle, linear-gradient(135deg, #707EFF 0%, #7F3DF6 100%));
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    padding: 0.5rem 2rem;
    cursor: pointer;
    border: none;
    margin-bottom: 20px;
    width: 100%;
    height: calc(1.5vw + 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
    margin-left: 7px;
  }
  
  .logout-button:hover {
    background: #FFF;
    color: var(--gradient-periwinkle, linear-gradient(135deg, #707EFF 0%, #7F3DF6 100%));
    box-shadow: 0px 0px 10px rgba(112, 126, 255, 0.3);
  }